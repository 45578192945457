import React from 'react'

export interface User {
  id: string
  firstName: string
  lastName: string
  avatarUrl?: string
}

const UserContext = React.createContext<User | null | undefined>(undefined)

export const UserProvider = UserContext.Provider
export const UserConsumer = UserContext.Consumer
export default UserContext
