import { useMutation } from '@apollo/react-hooks'
import {
  setFirstPassword,
  setFirstPasswordVariables
} from '__generated__/setFirstPassword'
import { validatePassword } from 'auth/services/UserInputValidation'
import { useFormik } from 'formik'
import { loader } from 'graphql.macro'
import useLoggedInCallback from 'hooks/LoggedInHook'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getErrorMessage } from 'utils'
import * as Yup from 'yup'

import SetFirstPasswordScreen from './SetFirstPassword.screen'
const SET_FIRST_PASSWORD = loader('./__graphql__/SetFirstPassword.graphql')

interface Props {
  pinNumber: string
  onCheckValid(invalidPinNumber: boolean): void
  onFirstPasswordChange(isFirstPasswordChange: boolean): void
}

const SetFirstPasswordContainer: React.FC<Props> = (props: Props) => {
  const { pinNumber, onCheckValid, onFirstPasswordChange } = props
  const history = useHistory()
  const loggedInCallback = useLoggedInCallback()
  const [doSetPassword] = useMutation<
    setFirstPassword,
    setFirstPasswordVariables
  >(SET_FIRST_PASSWORD)
  const [isValidating, setValidating] = React.useState(true)
  React.useEffect(() => {
    const checkPinNumber: () => void = async () => {
      try {
        const res = await doSetPassword({
          variables: {
            input: {
              pinNumber,
              newPassword: ''
            }
          }
        })
        switch (res.data?.setFirstPassword?.result.__typename) {
          case 'InvalidPinProblem':
            onCheckValid(false)
            break
        }
      } catch (err) {
        console.error('Error checking pin number', err)
      } finally {
        setValidating(false)
      }
    }
    checkPinNumber()
  }, [doSetPassword, onCheckValid, pinNumber])
  const formik = useFormik({
    initialValues: {
      pinNumber,
      newPassword: '',
      confirmPassword: ''
    },
    onSubmit: async (values, actions) => {
      if (values.newPassword !== values.confirmPassword) {
        actions.setStatus({ success: false, error: 'Passwords should match' })
        return
      }
      try {
        const res = await doSetPassword({
          variables: {
            input: {
              pinNumber: values.pinNumber,
              newPassword: values.newPassword
            }
          }
        })
        switch (res.data?.setFirstPassword?.result.__typename) {
          case 'SignInOk':
            const user: any =
              res.data.setFirstPassword.result.viewer.currentUser
            loggedInCallback?.(
              {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                avatarUrl: user.avatarUrl ?? undefined
              },
              res.data.setFirstPassword.result.jwt,
              res.data.setFirstPassword.result.jwtExpiry
            )
            actions.setStatus({ success: true })
            window.google?.accounts.id.cancel()
            onFirstPasswordChange(true)
            break
          case 'InvalidPinProblem':
            actions.setStatus({ success: false, error: 'Invalid Reset Token' })
            history.replace('/')
            break
          case 'ValidationProblem':
            actions.setStatus({ successs: false, error: 'Invalid Password' })
            break
        }
      } catch (err) {
        console.log(err)
        const errorMsg = getErrorMessage(err, 'Failed to reset password')
        actions.setStatus({ success: false, error: errorMsg })
      } finally {
        actions.setSubmitting(false)
      }
    },
    validationSchema: Yup.object().shape({
      pinNumber: Yup.string().required('Code is a required field'),
      newPassword: Yup.string()
        .required('Password is a required field')
        .test(
          'valid-password',
          'Password needs to be at least 8 characters long and contain one or more symbols',
          (value) => validatePassword(value)
        ),
      confirmPassword: Yup.string().required(
        '"Confirm Password" is a required field'
      )
    })
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <SetFirstPasswordScreen
        values={formik.values}
        onChange={formik.handleChange}
        isSending={formik.isSubmitting}
        errors={formik.errors}
        status={formik.status}
        setStatus={formik.setStatus}
        isValidating={isValidating}
      />
    </form>
  )
}

export default SetFirstPasswordContainer
