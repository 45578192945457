import { useQuery, useMutation } from '@apollo/react-hooks'
import { currentUser } from '__generated__/currentUser'
import { editUser, editUserVariables } from '__generated__/editUser'
import { useFormik } from 'formik'
import { loader } from 'graphql.macro'
import useLoggedInCallback from 'hooks/LoggedInHook'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getErrorMessage } from 'utils'
import * as Yup from 'yup'

import SetFirstProfileScreen from './SetFirstProfile.screen'

const CURRENT_USER = loader('../profile/__graphql__/CurrentUser.graphql')
const EDIT_USER = loader('./__graphql__/SetFirstProfile.graphql')

const SetFirstProfileContainer: React.FC<object> = () => {
  const loggedInCallback = useLoggedInCallback()
  const { error, data, loading } = useQuery<currentUser>(CURRENT_USER)
  const user = data?.viewer?.currentUser
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [editUserMutation] = useMutation<editUser, editUserVariables>(EDIT_USER)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      user || error
        ? {
            firstName: user?.firstName,
            lastName: user?.lastName
          }
        : {},
    onSubmit: async (values, actions) => {
      try {
        await editUserMutation({
          variables: {
            input: {
              data: {
                firstName: values.firstName ?? '',
                lastName: values.lastName ?? ''
              }
            }
          }
        })
        if (loggedInCallback) {
          loggedInCallback({
            id: '',
            firstName: values.firstName ?? '',
            lastName: values.lastName ?? ''
          })
        }
        actions.setStatus({ success: true })
        enqueueSnackbar('You have successfully created your account!', {
          variant: 'success'
        })
        history.replace('/')
      } catch (err) {
        console.error(err)
        const errorMsg = getErrorMessage(
          err,
          err.toString() || 'Failed to save'
        )
        actions.setStatus({ success: false, error: errorMsg })
      } finally {
        actions.setSubmitting(false)
      }
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('First Name is a required field'),
      lastName: Yup.string().required('Last Name is a required field')
    })
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <SetFirstProfileScreen
        loading={loading || Object.keys(formik.values).length === 0}
        values={formik.values}
        onChange={formik.handleChange}
        isSaving={formik.isSubmitting}
        error={
          error ? getErrorMessage(error, 'Failed to get user info') : undefined
        }
        errors={formik.errors}
        status={formik.status}
        setStatus={formik.setStatus}
        setFieldValue={formik.setFieldValue}
      />
    </form>
  )
}

export default SetFirstProfileContainer
