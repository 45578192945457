import React from 'react'

import InviteExpiredScreen from './InviteExpired.screen'

interface Props {}

const InviteExpiredContainer: React.FC<Props> = () => {
  return <InviteExpiredScreen />
}

export default InviteExpiredContainer
