import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Icon,
  Link,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import BusinessIcon from '@material-ui/icons/Business'
import Alert from '@material-ui/lab/Alert'
import React from 'react'
import { useHistory } from 'react-router-dom'

import Footer from '../appbar/footer'
import ProductCard from './ProductCard'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    paddingTop: 0,
    marginTop: theme.spacing(0),
    margin: 'auto',
    maxWidth: '100%',
    minHeight: 'calc(100vh - 175px)'
  },
  grid: {
    marginTop: theme.spacing(2),
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  companyCard: {
    padding: theme.spacing(1),
    background: 'rgba(255, 255, 255, 0.9)',
    width: '70%',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    margin: 'auto'
  },
  logo: {
    width: 100,
    height: 100,
    marginRight: '12px'
  },
  companyInfo: {
    marginTop: theme.spacing(2)
  },
  header: {
    color: theme.palette.primary.main,
    width: '70%',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    margin: 'auto'
  },
  products: {
    width: '70%',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    margin: 'auto'
  },
  vettedHeader: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  vettedInfoContainer: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  vettedInfo: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    alignItems: 'center',
    marginTop: theme.spacing(0.5)
  },
  vettedText: {
    marginLeft: theme.spacing(0.5)
  },
  checkIcon: {
    width: '16px',
    height: '16px'
  }
}))

export interface ProductValues {
  id: string
  imagesUrls?: string[]
  name: string
}

export interface CompanyValues {
  name: string
  website: string
  description: string
  vettingLevel: string
  foreignOwnership: string
  supplyChainVulnerabilities: string
  personnelThreatAssessment: string
  informationSecurity: string
  location: string
  logoUrl: string
  products?: ProductValues[]
}

interface Props {
  values: CompanyValues
  onProductCardClick(productId: string): void
  error?: string
  loading: boolean
}

const CompanyScreen: React.FC<Props> = (props: Props) => {
  const { values, onProductCardClick, loading, error } = props
  const history = useHistory()
  const classes = useStyles()

  var vettingShieldIconBig = ''
  var vettingShieldIconSmall = ''

  if (values.vettingLevel === 'Vetted') {
    vettingShieldIconBig = '/shield_green.png'
    vettingShieldIconSmall = '/shield_green_s.png'
  } else if (values.vettingLevel === 'Unvetted') {
    vettingShieldIconBig = '/shield_red.png'
    vettingShieldIconSmall = '/shield_red_s.png'
  } else {
    vettingShieldIconBig = '/shield_yellow.png'
    vettingShieldIconSmall = '/shield_yellow_s.png'
  }

  const getCheckIcon = (vettingLevel: string) => {
    if (vettingLevel === 'Vetted') {
      return '/check_green.png'
    } else if (vettingLevel === 'Unvetted') {
      return '/check_red.png'
    } else {
      return '/check_yellow.png'
    }
  }

  const getScreeningLevel = (vettingLevel: string) => {
    if (vettingLevel === 'Vetted') {
      return 'Pre-screened'
    } else if (vettingLevel === 'Unvetted') {
      return 'Not Pre-screened'
    } else {
      return 'Pre-screened, Concerns Documented'
    }
  }

  const companyAvatar = values.logoUrl ? (
    <Avatar className={classes.logo} variant="square" src={values.logoUrl}>
      {values.name}
    </Avatar>
  ) : (
    <Avatar className={classes.logo} variant="square">
      <BusinessIcon />
    </Avatar>
  )

  return (
    <>
      <div className={classes.root}>
        {error && <Alert severity="error">{error}</Alert>}
        {loading ? (
          <div style={{ textAlign: 'center', margin: 16, marginTop: 26 }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid
            container
            className={classes.grid}
            spacing={3}
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12} style={{ padding: 16 }}>
              <Button
                onClick={(): void => history.goBack()}
                color="secondary"
                startIcon={<ArrowBackIosIcon />}
                style={{ textTransform: 'none' }}
              >
                Back to Results
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Card className={classes.companyCard}>
                <CardHeader
                  avatar={companyAvatar}
                  title={
                    <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                      {values.name}
                    </Typography>
                  }
                  subheader={
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ marginRight: '4px' }}>
                          Screening Level:{' '}
                          {getScreeningLevel(values.vettingLevel)}
                        </Typography>
                        <Icon>
                          <img src={vettingShieldIconSmall} />
                        </Icon>
                      </div>
                      <Typography>Location: {values.location}</Typography>
                      <Link
                        href={values.website}
                        target="_blank"
                        color="secondary"
                      >
                        {values.website}
                      </Link>
                    </div>
                  }
                />
                <Divider />
                <CardContent>
                  <Typography
                    variant="h5"
                    className={classes.companyInfo}
                    style={{ fontWeight: 'bold' }}
                  >
                    About
                  </Typography>
                  <Typography className={classes.companyInfo}>
                    {values.description}
                  </Typography>
                  <div className={classes.vettedHeader}>
                    <Icon style={{ height: '30px', width: '30px' }}>
                      <img
                        src={vettingShieldIconBig}
                        style={{ height: '100%', width: '100%' }}
                      />
                    </Icon>
                    <Typography
                      variant="h5"
                      className={classes.vettedText}
                      style={{ fontWeight: 'bold' }}
                    >
                      Pre-screened For
                    </Typography>
                  </div>
                  <div className={classes.vettedInfoContainer}>
                    <div className={classes.vettedInfo}>
                      <Icon
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <img
                          src={getCheckIcon(values.foreignOwnership)}
                          className={classes.checkIcon}
                        />
                      </Icon>
                      <Typography className={classes.vettedText}>
                        Foreign Ownership
                      </Typography>
                    </div>
                    <div className={classes.vettedInfo}>
                      <Icon
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <img
                          src={getCheckIcon(values.supplyChainVulnerabilities)}
                          className={classes.checkIcon}
                        />
                      </Icon>
                      <Typography className={classes.vettedText}>
                        Supply Chain Vulnerabilities
                      </Typography>
                    </div>
                    <div className={classes.vettedInfo}>
                      <Icon
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <img
                          src={getCheckIcon(values.personnelThreatAssessment)}
                          className={classes.checkIcon}
                        />
                      </Icon>
                      <Typography className={classes.vettedText}>
                        Personnel Threat Assessment
                      </Typography>
                    </div>
                    <div className={classes.vettedInfo}>
                      <Icon
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <img
                          src={getCheckIcon(values.informationSecurity)}
                          className={classes.checkIcon}
                        />
                      </Icon>
                      <Typography className={classes.vettedText}>
                        Information Security
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.header}>
                Products ({values.products?.length})
              </Typography>
            </Grid>
            <Grid
              className={classes.products}
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              {values.products?.map((product) => (
                <Grid key={product.id} item xs={6} sm={6} lg={4} xl={3}>
                  <ProductCard values={product} onClick={onProductCardClick} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </div>
      <Footer />
    </>
  )
}

export default CompanyScreen
