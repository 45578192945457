import { useMutation } from '@apollo/react-hooks'
import {
  sendForgotPassword,
  sendForgotPasswordVariables
} from '__generated__/sendForgotPassword'
import { useFormik } from 'formik'
import { loader } from 'graphql.macro'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getErrorMessage } from 'utils'
import * as Yup from 'yup'

import SendForgotPasswordScreen from './SendForgotPassword.screen'
const SEND_FORGOT_PASSWORD = loader('./__graphql__/SendForgotPassword.graphql')

interface Props {}

const SendForgotPasswordContainer: React.FC<Props> = () => {
  const [sendEmail] = useMutation<
    sendForgotPassword,
    sendForgotPasswordVariables
  >(SEND_FORGOT_PASSWORD)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: async (values, actions) => {
      try {
        const signInRes = await sendEmail({
          variables: {
            input: {
              email: values.email
            }
          }
        })
        const result = signInRes?.data?.sendForgotPassword?.result
        if (result?.__typename !== 'NotFoundProblem') {
          actions.setStatus({ success: true })
          enqueueSnackbar(
            'You will receive a password reset email for your account.',
            {
              variant: 'success'
            }
          )
          history.replace('/login')
        } else {
          actions.setStatus({ success: false, error: result?.message })
        }
      } catch (err) {
        console.log(err)
        const errorMsg = getErrorMessage(err, 'Failed to save')
        actions.setStatus({ success: false, error: errorMsg })
      } finally {
        actions.setSubmitting(false)
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required('Email is a required field')
    })
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <SendForgotPasswordScreen
        values={formik.values}
        onChange={formik.handleChange}
        isSending={formik.isSubmitting}
        errors={formik.errors}
        status={formik.status}
        setStatus={formik.setStatus}
      />
    </form>
  )
}

export default SendForgotPasswordContainer
