import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import App from './App'
import theme from './ui/theme'

const { version } = require('../package.json')
const environment = process.env.REACT_APP_ENVIRONMENT
const dsn = process.env.REACT_APP_SENTRY_DSN
const release = `${environment}-${version}`

Sentry.init({
  dsn,
  environment,
  release,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
