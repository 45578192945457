import { useQuery } from '@apollo/react-hooks'
import { company } from '__generated__/company'
import { loader } from 'graphql.macro'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getErrorMessage } from 'utils'

import CompanyScreen from './Company.screen'

const COMPANY = loader('./__graphql__/Company.graphql')

interface Props {
  companyId: string
}

const CompanyContainer: React.FC<Props> = (props: Props) => {
  const history = useHistory()
  const { companyId } = props
  const { error, data, loading } = useQuery<company>(COMPANY, {
    variables: { id: companyId }
  })

  if (error) {
    history.replace('/')
  }

  const companyData = data?.company
  const values = {
    name: companyData?.name ?? '',
    website: companyData?.website ?? '',
    description: companyData?.description ?? '',
    vettingLevel: companyData?.vettingLevel ?? '',
    foreignOwnership: companyData?.foreignOwnership ?? '',
    supplyChainVulnerabilities: companyData?.supplyChainVulnerabilities ?? '',
    personnelThreatAssessment: companyData?.personnelThreatAssessment ?? '',
    informationSecurity: companyData?.informationSecurity ?? '',
    location: companyData?.location ?? '',
    logoUrl: companyData?.logoUrl ?? '',
    products: companyData?.products.map((product) => ({
      id: product?.id ?? '',
      name: product?.name ?? '',
      imagesUrls: product?.imagesUrls ?? []
    }))
  }

  const onProductCardClick = (productId: string): void => {
    history.push(`/product/${productId}`)
  }

  return (
    <CompanyScreen
      values={values}
      onProductCardClick={onProductCardClick}
      loading={loading}
      error={
        error ? getErrorMessage(error, 'Failed to get company info') : undefined
      }
    />
  )
}

export default CompanyContainer
