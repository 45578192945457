import { useQuery, useMutation } from '@apollo/react-hooks'
import { currentUser } from '__generated__/currentUser'
import {
  updateUserPassword,
  updateUserPasswordVariables
} from '__generated__/updateUserPassword'
import { validatePassword } from 'auth/services/UserInputValidation'
import { useFormik } from 'formik'
import { loader } from 'graphql.macro'
import useLoggedInCallback from 'hooks/LoggedInHook'
import { useSnackbar } from 'notistack'
import React from 'react'
import { getErrorMessage } from 'utils'
import * as Yup from 'yup'

import UpdateProfileScreen from './UpdateProfile.screen'

const CURRENT_USER = loader('./__graphql__/CurrentUser.graphql')
const UPDATE_USER_PASSWORD = loader('./__graphql__/UpdateUserPassword.graphql')

const UpdateProfileContainer: React.FC<object> = () => {
  const loggedInCallback = useLoggedInCallback()
  const { error, data, loading } = useQuery<currentUser>(CURRENT_USER)
  const user = data?.viewer?.currentUser

  const { enqueueSnackbar } = useSnackbar()

  const [updateUserPasswordMutation] = useMutation<
    updateUserPassword,
    updateUserPasswordVariables
  >(UPDATE_USER_PASSWORD)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      user || error
        ? {
            firstName: user?.firstName,
            lastName: user?.lastName,
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
          }
        : {},
    onSubmit: async (values, actions) => {
      try {
        await updateUserPasswordMutation({
          variables: {
            input: {
              oldPassword: values.currentPassword ?? '',
              newPassword: values.newPassword ?? ''
            }
          }
        })
        if (user && loggedInCallback) {
          loggedInCallback({
            id: '',
            firstName: user.firstName,
            lastName: user.lastName
          })
        }
        actions.setStatus({ success: true })
        enqueueSnackbar('Changed saved', { variant: 'success' })
      } catch (err) {
        console.error(err)
        const errorMsg = getErrorMessage(
          err,
          err.toString() || 'Failed to save'
        )
        actions.setStatus({ success: false, error: errorMsg })
      } finally {
        actions.setSubmitting(false)
      }
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('First Name is a required field'),
      lastName: Yup.string().required('Last Name is a required field'),
      currentPassword: Yup.string().required(
        'Current Password is a required field'
      ),
      newPassword: Yup.string()
        .required('New Password is a required field')
        .test(
          'valid-password',
          'Password needs to be at least 8 characters long and contain one or more symbols',
          (value) => validatePassword(value)
        ),
      confirmPassword: Yup.string()
        .required('Confirm Password is a required field')
        .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
    })
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <UpdateProfileScreen
        loading={loading || Object.keys(formik.values).length === 0}
        values={formik.values}
        onChange={formik.handleChange}
        isSaving={formik.isSubmitting}
        error={
          error ? getErrorMessage(error, 'Failed to get user info') : undefined
        }
        errors={formik.errors}
        status={formik.status}
        setStatus={formik.setStatus}
        setFieldValue={formik.setFieldValue}
        touched={formik.touched}
      />
    </form>
  )
}

export default UpdateProfileContainer
