import {
  IntrospectionFragmentMatcher,
  InMemoryCache,
  NormalizedCacheObject
} from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, fromPromise } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { ErrorLink, onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { ServerError } from 'apollo-link-http-common'
import FastMutex from 'fast-mutex'
import introspectionQueryResultData from 'fragmentTypes.json'

const url = process.env.REACT_APP_BACKEND_ENDPOINT

type Resolve = () => void
let isRefreshingJWT = false
let pendingRequests: Resolve[] = []

const resolvePendingRequests = () => {
  console.log('resolvePendingRequests')
  pendingRequests.map((resolve) => resolve())
  pendingRequests = []
}

const jwtRefreshEndpoint =
  url?.split('/').slice(0, -1).join('/') + '/refresh-token'

export const getJwtFromRefreshToken = async (): Promise<string | undefined> => {
  console.log('refreshJWT: start')
  let mutex
  try {
    const refreshToken = localStorage.getItem('refreshToken')
    mutex = new FastMutex({ timeout: 15000 })
    const mutexLockRes = await mutex.lock('sessionId')
    console.dir(mutexLockRes)
    const response: any = await fetch(jwtRefreshEndpoint, {
      headers: {
        'x-jwt-refresh-token': refreshToken ?? ''
      },
      method: 'POST',
      mode: 'cors',
      credentials: 'include'
    })
    if (response.ok) {
      const body = await response.json()
      localStorage.setItem('refreshToken', body?.jwt_refresh_token)
      jwtToken = body?.jwt
      return body?.jwt
    } else {
      throw new Error(`${response.status}`)
    }
  } catch (err) {
    console.error('Refresh token error', err)
    throw err
  } finally {
    await mutex?.release('sessionId')
  }
}

export const setJWT = (jwt: string | undefined) => {
  jwtToken = jwt
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

let jwtToken: string | undefined

export function createApolloClient(
  logoutCallback?: () => Promise<void>
): ApolloClient<NormalizedCacheObject> {
  const link = new HttpLink({
    uri: url,
    credentials: 'include'
  })

  const authHeaderLink = setContext((_, { headers }) => {
    const authorization = () =>
      jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}
    return {
      headers: {
        ...headers,
        ...authorization()
      }
    }
  })

  const onErrorCallback: ErrorLink.ErrorHandler = ({
    graphQLErrors,
    networkError,
    operation,
    forward
  }) => {
    console.log('onError')
    const statusCode = (networkError as ServerError)?.statusCode
    if (statusCode === 204 || statusCode >= 400) {
      console.log(
        `[Network error]: ${(networkError as ServerError).response.status}`
      )
      //return
    }

    if (graphQLErrors && Array.isArray(graphQLErrors)) {
      console.log('[GraphQL error]:', graphQLErrors)
      for (const error of graphQLErrors) {
        const { message, locations, path } = error
        if (message === 'JWTExpired') {
          console.log('JWT Expired, Refresh Token:')
          let _forward
          if (isRefreshingJWT) {
            // It will resolve only when we call pendingRequests
            _forward = fromPromise(
              new Promise((resolve) => {
                pendingRequests.push(() => resolve())
              })
            )
          } else {
            isRefreshingJWT = true
            _forward = fromPromise(
              getJwtFromRefreshToken()
                .then((jwt) => {
                  jwtToken = jwt
                  const { headers } = operation.getContext()
                  operation.setContext({
                    headers: {
                      ...headers,
                      Authorization: jwt ? `Bearer ${jwt}` : ''
                    }
                  })

                  isRefreshingJWT = false
                  resolvePendingRequests()
                  return jwt
                })
                .catch((error) => {
                  console.log('Error refreshJWT:', error)
                  isRefreshingJWT = false
                  pendingRequests = []
                  if (logoutCallback) {
                    logoutCallback()
                  }
                })
                .finally(() => {
                  console.log('refreshJWT done!')
                })
            ).filter((v) => Boolean(v))
          }

          return _forward.flatMap(() => {
            // retry the request, returning the new observable
            console.log('Forwarding operation')
            return forward(operation)
          })
        }

        if (message === 'Invalid JWT') {
          console.log('Invalid JWT, Clean Session:')
          if (logoutCallback) {
            logoutCallback()
          }
        }

        const m = `Message: ${message}`
        const l = `Location: ${JSON.stringify(locations)}`
        const p = `Path: ${path}`
        console.log(`[GraphQL error]: ${m}, ${l}, ${p}`)
      }
    }
  }
  const errorLink = onError(onErrorCallback)

  const cache = new InMemoryCache({
    fragmentMatcher
  })

  return new ApolloClient({
    link: ApolloLink.from([authHeaderLink, errorLink, link]),
    cache
  })
}
