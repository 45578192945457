const emailRE = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}'
const usernameRE = /^[A-Za-z0-9]+(?:[_-][A-Za-z0-9]+)*$/
// /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validateEmail = (email: string) => {
  const re = new RegExp(emailRE)
  return re.test(email) && email.split('@').length === 2
}

export const validatePassword = (password: string) => {
  // Password needs to be at least 8 characters long and contain one or more symbols
  return (
    // eslint-disable-next-line
    /[\/\:\"\.\,\?\';!@#$%^&*()=+_-]/.test(password) && password.length >= 8
  )
}

export const validateUserName = (username: string) => {
  const re = new RegExp(usernameRE)
  return re.test(username)
}
