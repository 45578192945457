import React from 'react'

import { User } from './UserContext'

const LoggedInContext = React.createContext<
  ((user: User, jwt?: string, jwtExpiry?: number) => void) | undefined
>(undefined)

export const LoggedInProvider = LoggedInContext.Provider
export const LoggedInConsumer = LoggedInContext.Consumer
export default LoggedInContext
