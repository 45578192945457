import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Alert from '@material-ui/lab/Alert'
import { FormikErrors } from 'formik'
import React from 'react'

import Footer from '../appbar/footer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2)
    },
    padding: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      padding: theme.spacing(1)
    },
    color: theme.palette.primary.main
  },
  grid: {
    marginTop: theme.spacing(2),
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  mainForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  button: {
    marginTop: theme.spacing(2),
    textTransform: 'capitalize',
    minWidth: 100,
    width: 200,
    borderRadius: 25,
    fontSize: '1rem'
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  header: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  label: {
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    background: theme.palette.primary.main
  },
  textField: {
    background: theme.palette.primary.main
  },
  adornedEnd: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main
    },
    '&:active': {
      background: theme.palette.primary.main
    },
    '&:focus': {
      background: theme.palette.primary.main
    }
  }
}))

export interface UpdateProfileFormValues {
  firstName?: string
  lastName?: string
  currentPassword?: string
  newPassword?: string
  confirmPassword?: string
}

interface Props {
  values: UpdateProfileFormValues
  onChange(ev: React.ChangeEvent<HTMLInputElement>): void
  setFieldValue(field: string, value: any): void
  isSaving: boolean
  errors?: FormikErrors<UpdateProfileFormValues>
  error?: string
  status?: any
  setStatus?(status: any): void
  loading: boolean
  touched?: any
}

const UpdateProfileScreen: React.FC<Props> = (props: Props) => {
  const {
    values,
    onChange,
    isSaving,
    errors,
    error,
    status,
    loading,
    touched
  } = props
  const {
    firstName,
    lastName,
    currentPassword,
    newPassword,
    confirmPassword
  } = values
  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {(error || (status?.error && !status.success)) && (
        <Alert severity="error">{error ?? status.error}</Alert>
      )}
      {!loading && (
        <Grid className={classes.grid} container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.mainForm}>
              <Typography className={classes.header} variant="h5">
                My Account
              </Typography>
              <Typography variant="subtitle2" className={classes.label}>
                First Name
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: classes.textField
                }}
                label="First Name"
                id="firstName"
                variant="filled"
                autoComplete="given-name"
                value={firstName}
                onChange={onChange}
                error={Boolean(errors?.firstName)}
                helperText={errors?.firstName}
                disabled
              />
              <Typography variant="subtitle2" className={classes.label}>
                Last Name
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: classes.textField
                }}
                id="lastName"
                label="Last Name"
                variant="filled"
                autoComplete="family-name"
                value={lastName}
                onChange={onChange}
                error={Boolean(errors?.lastName)}
                helperText={errors?.lastName}
                disabled
              />
              <Typography variant="subtitle2" className={classes.label}>
                Please contact us to change your name or email:
              </Typography>
              <Link
                href="mailto:support@hermesmarket.io"
                target="_blank"
                color="secondary"
              >
                support@hermesmarket.io
              </Link>
              <Divider className={classes.divider} />
              <Typography className={classes.header} variant="h5">
                Change Password
              </Typography>
              <Typography variant="subtitle2" className={classes.label}>
                Enter your current password
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: classes.textField
                }}
                id="currentPassword"
                label="Current Password"
                type={showPassword ? 'text' : 'password'}
                variant="filled"
                autoComplete="password"
                value={currentPassword}
                onChange={onChange}
                error={Boolean(
                  errors?.currentPassword && touched.currentPassword
                )}
                helperText={
                  touched.currentPassword ? errors?.currentPassword : ''
                }
                disabled={isSaving}
                InputProps={{
                  classes: {
                    adornedEnd: classes.adornedEnd
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Typography variant="subtitle2" className={classes.label}>
                Enter your new password
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: classes.textField
                }}
                id="newPassword"
                label="New Password"
                type="password"
                variant="filled"
                autoComplete="new-password"
                value={newPassword}
                onChange={onChange}
                error={Boolean(errors?.newPassword && touched.newPassword)}
                helperText={touched.newPassword ? errors?.newPassword : ''}
                disabled={isSaving}
              />
              <Typography variant="subtitle2" className={classes.label}>
                Re-enter your new password
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: classes.textField
                }}
                id="confirmPassword"
                label="Confirm Password"
                type="password"
                variant="filled"
                autoComplete="off"
                value={confirmPassword}
                onChange={onChange}
                error={Boolean(
                  errors?.confirmPassword && touched.confirmPassword
                )}
                helperText={
                  touched.confirmPassword ? errors?.confirmPassword : ''
                }
                disabled={isSaving}
              />
              <div className={classes.center}>
                <Button
                  type="submit"
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={isSaving}
                >
                  <Box fontWeight="fontWeightBold">Save changes</Box>
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <Footer />
    </div>
  )
}

export default UpdateProfileScreen
