import React from 'react'
import { useHistory } from 'react-router-dom'

import SignUpSuccessScreen from './SignUpSuccess.screen'

interface Props {}

const SignUpSuccessContainer: React.FC<Props> = () => {
  const history = useHistory()
  const handleClose: () => void = () => {
    history.replace('/')
  }
  return <SignUpSuccessScreen onClick={handleClose} />
}

export default SignUpSuccessContainer
