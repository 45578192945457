import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useCurrentUser from 'hooks/UserHook'
import SearchContainer from 'modules/search/Search.container'
import React from 'react'
import { useHistory, Link as RouterLink } from 'react-router-dom'

import Footer from '../modules/appbar/footer'
import GA from '../service/GoogleAnalytics'
import waves from '../ui/hermes-waves.svg'

const PARTNER_GOOGLE_FORMS_URL = process.env.REACT_APP_PARTNER_GOOGLE_FORMS_URL

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    },
    backgroundImage: `url(${waves})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '-300px',
    backgroundSize: '150%',
    minHeight: 'calc(100vh - 175px)',
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '200%'
    },
    maxWidth: '100%'
  },
  cardMembers: {
    minWidth: 360,
    maxWidth: 640,
    border: 'none',
    boxShadow: 'none',
    background: 'inherit',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      minWidth: '90%',
      width: '90%',
      margin: 'auto'
    }
  },
  cardPartners: {
    borderRadius: 10,
    maxWidth: 960,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  },
  title: {
    marginRight: theme.spacing(2)
  },
  subtitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  subtitlePartners: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  button: {
    textTransform: 'capitalize',
    borderRadius: 25,
    marginBottom: theme.spacing(1),
    fontSize: '1rem'
  }
}))

interface Props {}

const HomePage: React.FC<Props> = () => {
  const history = useHistory()
  const user = useCurrentUser()
  const classes = useStyles()
  const handleMemberClick: () => void = () => {
    GA.sendEvent({
      category: 'Register',
      action: 'Register as a Member'
    })
    history.replace('/signup')
  }
  const handlePartnerClick: () => void = () => {
    GA.sendEvent({
      category: 'Register',
      action: 'Register as a Partner'
    })
    const win = window.open(PARTNER_GOOGLE_FORMS_URL, '_blank')
    if (win != null) {
      win.focus()
    }
  }
  return (
    <>
      <div className={classes.root}>
        {user ? (
          <Grid
            container
            spacing={3}
            justify="center"
            alignItems="center"
            style={{ width: '100%' }}
          >
            <SearchContainer />
          </Grid>
        ) : (
          <Grid
            className={classes.root}
            container
            spacing={3}
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Card className={classes.cardMembers}>
                <CardContent>
                  <Typography
                    color="primary"
                    variant="h6"
                    className={classes.title}
                  >
                    Welcome to the
                  </Typography>
                  <Typography
                    color="primary"
                    variant="h4"
                    className={classes.title}
                  >
                    <Box fontWeight="fontWeightBold" m={1}>
                      Hermes Technology Marketplace
                    </Box>
                  </Typography>
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    className={classes.subtitle}
                  >
                    Hermes is a platform where the lineage and quality of
                    mature, cutting-edge capabilities is undisputed.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleMemberClick}
                    className={classes.button}
                  >
                    <Box fontWeight="fontWeightBold">
                      Request to Become a Member
                    </Box>
                  </Button>
                  <Typography variant="body2" component="p" color="primary">
                    Already a Member? Click to&nbsp;
                    <Typography
                      component={RouterLink}
                      to="/login"
                      variant="body2"
                      color="primary"
                    >
                      login
                    </Typography>
                    .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card className={classes.cardPartners}>
                <CardContent>
                  <Typography
                    color="inherit"
                    variant="h5"
                    className={classes.title}
                  >
                    <Box fontWeight="fontWeightBold" m={1}>
                      Feature your products or service on the Marketplace
                    </Box>
                  </Typography>
                  <Typography
                    color="inherit"
                    variant="subtitle1"
                    className={classes.subtitlePartners}
                  >
                    <Box maxWidth={640}>
                      Work with shared purpose to solve national security
                      problems and bring world class technology to defense and
                      intelligence professionals
                    </Box>
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePartnerClick}
                    className={classes.button}
                  >
                    <Box fontWeight="fontWeightBold">
                      Request to Become a Partner
                    </Box>
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card className={classes.cardPartners}>
                <CardContent>
                  <Typography
                    color="inherit"
                    variant="h5"
                    className={classes.title}
                  >
                    <Box fontWeight="fontWeightBold" m={1}>
                      About Hermes
                    </Box>
                  </Typography>
                  <Typography
                    color="inherit"
                    variant="subtitle1"
                    className={classes.subtitlePartners}
                  >
                    <Box maxWidth={640}>
                      Explore the commercial technology landscape with
                      confidence and innovate ahead of the competition. The
                      Hermes Marketplace offers a curated collection of
                      cutting-edge solutions that speak directly to existing and
                      imminent capability needs.
                    </Box>
                  </Typography>
                  <Typography variant="body2" component="p" color="inherit">
                    Questions or comments? Get in touch with us&nbsp;
                    <Link
                      href="mailto:contact@hermesmarket.io"
                      target="_blank"
                      color="inherit"
                      underline="always"
                    >
                      here
                    </Link>
                    .
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </div>
      <Footer />
    </>
  )
}

export default HomePage
