import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { FormikErrors } from 'formik'
import React from 'react'

import Footer from '../appbar/footer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    },
    minHeight: 'calc(100vh - 175px)',
    textAlign: 'center'
  },
  subtitle: {
    marginTop: theme.spacing(2)
  },
  grid: {
    marginTop: theme.spacing(2),
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  mainForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    right: theme.spacing(0),
    textTransform: 'capitalize',
    minWidth: 100,
    borderRadius: 25,
    margin: theme.spacing(1),
    fontSize: '1rem'
  },
  textField: {
    background: theme.palette.primary.main
  }
}))

export interface SignUpFormValues {
  email?: string
  firstName?: string
  lastName?: string
}

interface Props {
  values: SignUpFormValues
  onChange(ev: React.ChangeEvent<HTMLInputElement>): void
  isSigningIn: boolean
  errors?: FormikErrors<SignUpFormValues>
  status?: any
  setStatus?(status: any): void
}

const SignUpScreen: React.FC<Props> = (props: Props) => {
  const { values, onChange, isSigningIn, errors, status } = props
  const { email, firstName, lastName } = values
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        {status?.error && !status.success && (
          <Alert severity="error">{status.error}</Alert>
        )}
        <Typography color="primary" variant="h4">
          We are evaluating initial memberships on a case-by-case basis.
        </Typography>
        <Typography
          color="primary"
          variant="subtitle1"
          className={classes.subtitle}
        >
          If you are interested, please fill out the information below.
        </Typography>
        <Grid className={classes.grid} container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.mainForm}>
              <TextField
                fullWidth
                inputProps={{
                  className: classes.textField
                }}
                color="secondary"
                id="firstName"
                label="First Name"
                variant="filled"
                autoComplete="given-name"
                value={firstName}
                onChange={onChange}
                error={Boolean(errors?.firstName)}
                helperText={errors?.firstName}
              />
              <TextField
                fullWidth
                inputProps={{
                  className: classes.textField
                }}
                color="secondary"
                id="lastName"
                label="Last Name"
                variant="filled"
                autoComplete="family-name"
                value={lastName}
                onChange={onChange}
                error={Boolean(errors?.lastName)}
                helperText={errors?.lastName}
              />
              <TextField
                fullWidth
                inputProps={{
                  className: classes.textField
                }}
                color="secondary"
                id="email"
                type="email"
                label="Email Address"
                autoComplete="email"
                variant="filled"
                value={email}
                onChange={onChange}
                error={Boolean(errors?.email)}
                helperText={errors?.email}
              />
              <Button
                type="submit"
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={isSigningIn}
              >
                <Box fontWeight="fontWeightBold">Submit Request</Box>
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  )
}

export default SignUpScreen
