import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import Footer from '../modules/appbar/footer'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
      padding: theme.spacing(1)
    },
    padding: theme.spacing(4),
    minHeight: 'calc(100vh - 200px)'
  },
  header: {
    marginBottom: theme.spacing(4)
  },
  terms: {
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  }
}))

interface Props {}

const TermsPage: React.FC<Props> = () => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h3" color="primary" className={classes.header}>
          Terms of Use
        </Typography>
        <Grid container spacing={3}>
          <Grid container xs={12} lg={6}>
            <Grid item className={classes.terms}>
              <Typography variant="h6" color="secondary">
                I. Proprietary Information
              </Typography>
              <Typography color="primary">
                Levenhall, Inc reserves all right, title, and ownership of
                intellectual property hosted on Hermesmarket.io, including but
                not limited to logos, images, text, data and information that
                may be considered a trade secret regardless of notification of
                protection under U.S. or foreign law. Third party content (e.g.
                the Twitter logo) is the protected property of that respective
                third party. Any use of content described in this section by you
                may constitute infringement and may be punishable by law.
              </Typography>
            </Grid>
            <Grid item className={classes.terms}>
              <Typography variant="h6" color="secondary">
                II. Third Party Components
              </Typography>
              <Typography color="primary">
                You acknowledge and agree that Hermesmarket.io may contain open
                source software or other third party software components that
                are licensed under the terms of an open source license or
                agreement with an applicable third party licensor, and that no
                right, title or interest in or to ant Third Party Components or
                any intellectual property rights therein are transferred to you
                by way of this agreement. These Third Party Components are
                provided to you on an “AS IS” BASIS WITHOUT WARRANTIES OF ANY
                KIND unless specified otherwise. However, such Third Party
                Components may carry their own warranties and license that shall
                pass through to you to the extent authorized . Exercise of such
                warranty will be directly between you and the applicable third
                party provider.
              </Typography>
            </Grid>
            <Grid item className={classes.terms}>
              <Typography variant="h6" color="secondary">
                III. Representations and Warranties
              </Typography>
              <Typography color="primary">
                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, LEVENHALL,
                INC DOES NOT PROVIDE ANY WARRANTIES, EITHER EXPRESS OR IMPLIED,
                INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                PARTICULAR ACCURACY OF DATA, NONINFRINGEMENT, AND ALL SUCH
                WARRANTIES ARE HEREBY EXPRESSLY DISCLAIMED BY LEVENHALL, INC
                WITH REGARD TO HERMESMARKET.IO INCLUDING ANY LINKS TO THIRD
                PARTY CONTENT. You agree and acknowledge that You are using
                Hermesmarket.io at your own risk, and that it is being provided
                to you on an “AS IS” and “AS AVAILABLE” basis. You further agree
                that to the extent permitted by applicable law Levenhall, Inc
                its members and employees are not liable for any damages or
                losses (including, but not limited to loss of money goodwill or
                reputation profits or other intangible losses, or any special,
                indirect, or consequential damages) resulting directly or
                indirectly from your use of, or inability to Hermesmarket.io.
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={12} lg={6}>
            <Grid item className={classes.terms}>
              <Typography variant="h6" color="secondary">
                IV. Privacy and User Content
              </Typography>
              <Typography color="primary">
                Any information you enter into or materials you upload to the
                Hermes website (your name, e-mail address, etc.) will be
                viewable by Levenhall, Inc members and employees and may be
                retained and archived indefinitely for operational and legal
                purposes. You agree to grant us a perpetual license to use any
                such information and materials for any purpose reasonably
                related to the operation of Hermesmarket.io, hiring of new
                employees, or otherwise required by law.
              </Typography>
            </Grid>
            <Grid item className={classes.terms}>
              <Typography variant="h6" color="secondary">
                V. Limits on Use
              </Typography>
              <Typography color="primary">
                While accessing Hermesmarket.io, you are prohibited from
                engaging in any illegal conduct or conduct that may threaten or
                system of network security or expose us or any of our users to
                any liability or potential harm.
              </Typography>
            </Grid>
            <Grid item className={classes.terms}>
              <Typography variant="h6" color="secondary">
                VI. Governing Law and Venue
              </Typography>
              <Typography color="primary">
                You agree that, except to the extent inconsistent with or
                preempted by federal law, the laws of the State of Delaware,
                without regard to principles of conflict of laws, will govern
                this agreement and any claim or dispute that has arisen or may
                arise between you and Levenhall, Inc relating to your use of
                Hermesmarket.io. For all such disputes and claims, you agree to
                submit to the personal jurisdiction of the federal and state
                courts located in Delaware.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  )
}

export default TermsPage
