import { ApolloError } from 'apollo-boost'
import { ErrorResponse } from 'apollo-link-error'
import EXIF from 'exif-js'

export const getErrorMessage = (
  err: ErrorResponse | ApolloError,
  fallback: string
): string =>
  err.graphQLErrors && err.graphQLErrors.length > 0
    ? err.graphQLErrors[0].message
    : err.networkError?.message ?? fallback

const ExifInterface = {
  3: 180,
  8: 270,
  6: 90,
  1: 0
}

export const getRotation = (orientation: 1 | 3 | 6 | 8): number =>
  orientation && window.navigator.platform !== 'iPhone'
    ? ExifInterface[orientation] || 0
    : 0

export const getExif = (file: File): Promise<1 | 3 | 6 | 8 | null> => {
  return new Promise((resolve) => {
    EXIF.getData(file as any, () => {
      const orient = EXIF.getTag(file, 'Orientation')
      resolve(orient)
    })
  })
}

export const readImageFromFile = (
  file: File
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, _) => {
    const reader = new window.FileReader()
    reader.onload = (e: ProgressEvent<FileReader>): void => {
      resolve(e?.target?.result)
    }
    reader.readAsDataURL(file)
  })
}

export const preventSilentSignin = (): void => {
  if (window.PasswordCredential || window.FederatedCredential) {
    navigator?.credentials?.preventSilentAccess()
  }
}

export const getAvatarLetter = (user: {
  firstName?: string
  lastName?: string
}): string =>
  [user.firstName, user.lastName]
    .map((name) => (name && name.length > 0 ? name[0] : ''))
    .join('')
