import { ApolloProvider } from '@apollo/react-hooks'
import LinearProgress from '@material-ui/core/LinearProgress'
import { signOut } from '__generated__/signOut'
import ApolloClient from 'apollo-boost'
import Session, { UserSession, userSessionKey } from 'auth/services/UserSession'
import { LoggedInProvider } from 'context/LoggedInContext'
import { LogoutProvider } from 'context/LogoutContext'
import { UserProvider, User } from 'context/UserContext'
import { loader } from 'graphql.macro'
import AppBar from 'modules/appbar/appbar'
import { SnackbarProvider } from 'notistack'
import BrowsePage from 'pages/browse'
import Company from 'pages/company'
import Home from 'pages/home'
import Product from 'pages/product'
import ResetPassword from 'pages/resetpassword'
import SetFirstPassword from 'pages/setfirstpassword'
import SignIn from 'pages/signin'
import SignUp from 'pages/signup'
import Terms from 'pages/terms'
import UpdateProfile from 'pages/updateprofile'
import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Analytics from 'react-router-ga'
import {
  getJwtFromRefreshToken,
  setJWT,
  createApolloClient
} from 'service/ApolloClient'
import { preventSilentSignin } from 'utils'

import GA from './service/GoogleAnalytics'

const SIGNOUT_MUTATION = loader('./auth/__graphql__/SignOutMutation.graphql')

const currentSession = Session.currentSession()

const App: React.FC = () => {
  const [isSigninOut, setIsSigninOut] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const logout = async (client?: ApolloClient<any>) => {
    let loggedOut = false
    preventSilentSignin()
    setIsSigninOut(true)
    try {
      const resp = await client?.mutate<signOut>({
        mutation: SIGNOUT_MUTATION
      })
      if (resp) {
        loggedOut = !!(resp.data && 'signOut' in resp.data)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setJWT(undefined)
      const session = Session.logout()
      setSession(session)
      setIsSigninOut(false)
    }
    if (loggedOut) {
      window.location.href = '/'
    }
  }

  const apolloClient = React.useRef<ApolloClient<any>>(
    createApolloClient(logout)
  )

  const [session, setSession] = React.useState<UserSession>(currentSession)

  const onLoggedIn = (user: User, jwt?: string, jwtExpiry?: number) => {
    if (jwt) {
      setJWT(jwt)
    }
    const session = Session.login(user)
    setSession(session)
  }

  const checkSession = async (seesion: UserSession) => {
    if (session.user) {
      try {
        await getJwtFromRefreshToken()
      } catch (err) {
        if (err.toString() === '403') {
          const session = Session.logout()
          setSession(session)
        }
      }
    }
    setIsLoading(false)
  }

  const onStorage = (e: StorageEvent) => {
    if (e.key === userSessionKey) {
      if (e.oldValue && !e.newValue && session?.user) {
        console.log('User logged out on another tab')
        const session = Session.currentSession()
        setSession(session)
      } else if (!e.oldValue && e.newValue) {
        console.log('User logged in on another tab')
      }
    }
  }

  React.useEffect(() => {
    checkSession(currentSession)
    window.addEventListener('storage', onStorage)
    return () => {
      window.removeEventListener('storage', onStorage)
    }
  }, [])

  return isLoading ? (
    <LinearProgress />
  ) : (
    <ApolloProvider client={apolloClient.current}>
      <LogoutProvider value={() => logout(apolloClient.current)}>
        <UserProvider value={session?.user}>
          <LoggedInProvider value={onLoggedIn}>
            <SnackbarProvider maxSnack={3}>
              <Router>
                <div>
                  <AppBar />
                  {isSigninOut && <LinearProgress />}
                  <Analytics id={GA.GOOGLE_TRACKING_ID}>
                    <Switch>
                      <Route path="/login">
                        <SignIn />
                      </Route>
                      <Route path="/signup">
                        <SignUp />
                      </Route>
                      <Route path="/reset-password">
                        <ResetPassword />
                      </Route>
                      <Route path="/setfirstPassword">
                        <SetFirstPassword />
                      </Route>
                      <Route path="/profile">
                        <UpdateProfile />
                      </Route>
                      <Route path="/browse">
                        <BrowsePage />
                      </Route>
                      <Route path="/product/:productId">
                        <Product />
                      </Route>
                      <Route path="/company/:companyId">
                        <Company />
                      </Route>
                      <Route path="/terms">
                        <Terms />
                      </Route>
                      <Route path="/">
                        <Home />
                      </Route>
                    </Switch>
                  </Analytics>
                </div>
              </Router>
            </SnackbarProvider>
          </LoggedInProvider>
        </UserProvider>
      </LogoutProvider>
    </ApolloProvider>
  )
}

export default App
