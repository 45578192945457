import {
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import { useFormik } from 'formik'
import React from 'react'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  form: {
    display: 'flex',
    flex: 1
  },
  textFieldContainer: {
    flex: 1
  },
  textField: {
    background: theme.palette.primary.main,
    height: 12,
    borderRadius: '25px 0 0 25px'
  },

  adornedEnd: {
    borderRadius: '25px 25px 25px 25px',
    background: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.main
    },
    '&:active': {
      background: theme.palette.secondary.main
    },
    '&:focus': {
      background: theme.palette.secondary.main
    }
  }
}))

interface Props {}

const SearchBar: React.FC<Props> = () => {
  const classes = useStyles()
  const history = useHistory()
  const formik = useFormik({
    initialValues: {
      search: ''
    },
    onSubmit: async (values, actions) => {
      history.push(`/browse?search=${values.search}`)
      actions.resetForm()
    }
  })

  return (
    <div className={classes.root}>
      {formik.isSubmitting && <LinearProgress />}
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <TextField
          fullWidth
          className={classes.textFieldContainer}
          inputProps={{
            className: classes.textField
          }}
          color="secondary"
          id="search"
          type="text"
          variant="outlined"
          autoComplete="none"
          value={formik.values.search}
          onChange={formik.handleChange}
          InputProps={{
            classes: {
              adornedEnd: classes.adornedEnd
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  type="submit"
                  aria-label="search"
                  style={{ padding: '0' }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </form>
    </div>
  )
}

export default SearchBar
