import useCurrentUser from 'hooks/UserHook'
import CatalogContainer from 'modules/search/Catalog.container'
import React from 'react'
import { useHistory } from 'react-router-dom'

interface Props {}

const BrowsePage: React.FC<Props> = () => {
  const user = useCurrentUser()
  const history = useHistory()
  React.useEffect(() => {
    if (!user) {
      history.replace('/login')
    }
  }, [])

  return user ? <CatalogContainer /> : null
}

export default BrowsePage
