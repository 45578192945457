import { User } from 'context/UserContext'

export interface UserSession {
  user?: User | null
}

export const userSessionKey = 'USER_DATA'

const restoreSession = (): UserSession => {
  const data = window.localStorage.getItem(userSessionKey)
  const userData: User | null = data ? JSON.parse(data) : null
  console.log(
    `Restoring User Session, user data: ${userData ? 'exists' : 'empty'}`
  )

  return {
    user: userData
      ? {
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          avatarUrl: userData.avatarUrl
        }
      : null
  }
}

let session: UserSession | undefined

const logout = (): UserSession => {
  window.localStorage.removeItem(userSessionKey)
  session = restoreSession()
  return session
}

const login = (user: User): UserSession => {
  console.log('Write session to storage')
  const userData = JSON.stringify(user)
  window.localStorage.setItem(userSessionKey, userData)
  session = {
    user
  }
  return session
}

const currentSession = (): UserSession => {
  session = restoreSession()
  return session
}

export default {
  currentSession,
  login,
  logout
}
