import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Icon,
  Typography
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 250,
    height: 354,
    maxWidth: 250,
    margin: 'auto',
    border: 'none',
    boxShadow: 'none',
    background: 'inherit',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      width: 150,
      maxWidth: 150,
      height: 224
    }
  },
  image: {
    width: '100%',
    height: 250,
    marginBottom: theme.spacing(1),
    backgroundSize: 'cover',
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      height: 150
    }
  },
  product: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  company: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  action: {
    '&:hover $focusHighlight': {
      opacity: 0
    },
    width: '100%'
  },
  focusHighlight: {},
  content: {
    display: 'content',
    padding: 0
  }
}))

export interface ProductCardValues {
  id: string
  name: string
  vettingLevel?: string
  imagesUrls?: string[] | null
  location?: string
  companyName?: string
}

interface Props {
  values: ProductCardValues
  onClick(productId: string): void
}

const ProductCard: React.FC<Props> = (props: Props) => {
  const { values, onClick } = props
  const classes = useStyles()

  let vettingShieldIcon = ''

  if (values.vettingLevel === 'Vetted') {
    vettingShieldIcon = '/shield_green_s.png'
  } else if (values.vettingLevel === 'Unvetted') {
    vettingShieldIcon = '/shield_red_s.png'
  } else {
    vettingShieldIcon = '/shield_yellow_s.png'
  }

  const getScreeningLevel = (vettingLevel: string) => {
    if (vettingLevel === 'Vetted') {
      return 'Pre-screened'
    } else if (vettingLevel === 'Unvetted') {
      return 'Not Pre-screened'
    } else {
      return 'Pre-screened, Concerns Documented'
    }
  }

  const imageUrl =
    values.imagesUrls && values.imagesUrls.length !== 0
      ? values.imagesUrls[0]
      : '/placeholder.svg'

  return (
    <Card className={classes.root} style={{ width: '100%' }}>
      <CardActionArea
        classes={{
          root: classes.action,
          focusHighlight: classes.focusHighlight
        }}
        onClick={() => onClick(values.id)}
      >
        <CardMedia
          className={classes.image}
          image={imageUrl}
          title={values.name}
        />
        <CardContent className={classes.content}>
          <Typography noWrap variant="body1" className={classes.product}>
            {values.name}
          </Typography>
          {values.companyName && values.location && (
            <Typography noWrap variant="body2" className={classes.company}>
              {values.companyName} | {values.location}
            </Typography>
          )}
          {values.vettingLevel && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" style={{ marginRight: '4px' }} noWrap>
                Screening Level: {getScreeningLevel(values.vettingLevel)}
              </Typography>
              <Icon>
                <img src={vettingShieldIcon} />
              </Icon>
            </div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default ProductCard
