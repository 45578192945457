import { useMutation } from '@apollo/react-hooks'
import { signUp, signUpVariables } from '__generated__/signUp'
import { useFormik, FormikErrors } from 'formik'
import { loader } from 'graphql.macro'
import useLoggedInCallback from 'hooks/LoggedInHook'
import React from 'react'
import { getErrorMessage } from 'utils'
import * as Yup from 'yup'

import SignUpScreen, { SignUpFormValues } from './SignUp.screen'
const SIGNUP = loader('./__graphql__/SignUp.graphql')

interface Props {
  onComplete(isSignUpComplete: boolean): void
}

const SignInContainer: React.FC<Props> = (props: Props) => {
  const { onComplete } = props
  const loggedInCallback = useLoggedInCallback()
  const [signUpMutation] = useMutation<signUp, signUpVariables>(SIGNUP)
  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: ''
    },
    onSubmit: async (values, actions) => {
      try {
        const signInRes = await signUpMutation({
          variables: {
            input: {
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName
            }
          }
        })
        const result = signInRes?.data?.signUp?.result
        if (result?.__typename === 'SignUpOk') {
          const user = result.viewer.currentUser
          if (user && loggedInCallback) {
            actions.setStatus({ success: true })
            onComplete(true)
          } else {
            actions.setStatus({ success: false, error: 'Error' })
          }
        } else if (result?.__typename === 'ValidationProblem') {
          actions.setStatus({ success: false, error: result.message })
          if (result.errors && result.errors.length > 0) {
            const formikErrors: FormikErrors<SignUpFormValues> = {}
            result.errors.forEach(
              (err) =>
                (formikErrors[err.field as 'email'] = err.messages.join(', '))
            )
            actions.setErrors(formikErrors)
          }
        }
      } catch (err) {
        console.log(err)
        const errorMsg = getErrorMessage(err, 'Failed to save')
        actions.setStatus({ success: false, error: errorMsg })
      } finally {
        actions.setSubmitting(false)
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required('Email is a required field'),
      firstName: Yup.string().required('First Name is a required field'),
      lastName: Yup.string().required('Last Name is a required field')
    })
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <SignUpScreen
        values={formik.values}
        onChange={formik.handleChange}
        isSigningIn={formik.isSubmitting}
        errors={formik.errors}
        status={formik.status}
        setStatus={formik.setStatus}
      />
    </form>
  )
}

export default SignInContainer
