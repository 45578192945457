import { useMutation } from '@apollo/react-hooks'
import {
  resetPassword,
  resetPasswordVariables
} from '__generated__/resetPassword'
import { validatePassword } from 'auth/services/UserInputValidation'
import { useFormik } from 'formik'
import { loader } from 'graphql.macro'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getErrorMessage } from 'utils'
import * as Yup from 'yup'

import ResetPasswordScreen from './ResetPassword.screen'
const RESET_PASSWORD = loader('./__graphql__/ResetPassword.graphql')

interface Props {
  pinNumber: string
}

const ResetPasswordContainer: React.FC<Props> = (props: Props) => {
  const { pinNumber } = props
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [doResetPassword] = useMutation<resetPassword, resetPasswordVariables>(
    RESET_PASSWORD
  )
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: ''
    },
    onSubmit: async (values, actions) => {
      if (values.newPassword !== values.confirmPassword) {
        actions.setStatus({ success: false, error: 'Passwords should match' })
        return
      }
      try {
        await doResetPassword({
          variables: {
            input: {
              pinNumber,
              newPassword: values.newPassword
            }
          }
        })
        actions.setStatus({ success: true })
        enqueueSnackbar(
          'Your password is successfully saved. You can now log in using your email and new password.',
          { variant: 'success' }
        )
      } catch (err) {
        console.log(err)
        const errorMsg = getErrorMessage(err, 'Failed to reset password')
        actions.setStatus({ success: false, error: errorMsg })
        enqueueSnackbar(errorMsg, { variant: 'error' })
      } finally {
        actions.setSubmitting(false)
        history.replace('/login')
      }
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .required('Password is a required field')
        .test(
          'valid-password',
          'Password needs to be at least 8 characters long and contain one or more symbols',
          (value) => validatePassword(value)
        ),
      confirmPassword: Yup.string().required(
        '"Confirm Password" is a required field'
      )
    })
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <ResetPasswordScreen
        values={formik.values}
        onChange={formik.handleChange}
        isSending={formik.isSubmitting}
        errors={formik.errors}
        status={formik.status}
        setStatus={formik.setStatus}
      />
    </form>
  )
}

export default ResetPasswordContainer
