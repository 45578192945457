import useCurrentUser from 'hooks/UserHook'
import ProductContainer from 'modules/product/Product.container'
import React from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

interface Props {}

const ProductPage: React.FC<Props> = () => {
  const { productId } = useParams<{ productId: string }>()
  const user = useCurrentUser()
  const history = useHistory()
  React.useEffect(() => {
    if (!user) {
      history.replace('/login')
    }
  }, [])
  return user ? <ProductContainer productId={productId} /> : null
}

export default ProductPage
