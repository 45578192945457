import useCurrentUser from 'hooks/UserHook'
import UpdateProfileContainer from 'modules/profile/UpdateProfile.container'
import React from 'react'
import { useHistory } from 'react-router-dom'

interface Props {}

const UpdateProfilePage: React.FC<Props> = () => {
  const user = useCurrentUser()
  const history = useHistory()
  React.useEffect(() => {
    if (!user) {
      history.replace('/login')
    }
  }, [])
  return user ? <UpdateProfileContainer /> : null
}

export default UpdateProfilePage
