import ReactGA from 'react-ga'

const GOOGLE_TRACKING_ID = process.env.REACT_APP_GOOGLE_TRACKING_ID

const DEBUG = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

let initialized = false

const init = (): void => {
  if (!GOOGLE_TRACKING_ID) {
    throw new Error('Google Client ID is not set')
  }
  if (!initialized) {
    ReactGA.initialize(GOOGLE_TRACKING_ID, { debug: DEBUG })
    initialized = true
  }
}

const sendEvent = (payload: any): void => {
  if (!initialized) {
    init()
  }
  ReactGA.event(payload)
}

const sendPageview = (path: string): void => {
  if (!initialized) {
    init()
  }
  ReactGA.set({ page: path })
  ReactGA.pageview(path)
}

export default {
  init,
  sendEvent,
  sendPageview,
  GOOGLE_TRACKING_ID
}
