import SignUpContainer from 'modules/auth/SignUp.container'
import SignUpSuccessContainer from 'modules/auth/SignUpSuccess.container'
import React from 'react'

interface Props {}

const SignUpPage: React.FC<Props> = () => {
  const [isSignUpComplete, setSignUpComplete] = React.useState(false)
  return !isSignUpComplete ? (
    <SignUpContainer onComplete={setSignUpComplete} />
  ) : (
    <SignUpSuccessContainer />
  )
}

export default SignUpPage
