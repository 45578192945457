import { useQuery } from '@apollo/react-hooks'
import { products } from '__generated__/products'
import { loader } from 'graphql.macro'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import CatalogScreen from './Catalog.screen'

const queryString = require('query-string')

const PRODUCTS = loader('./__graphql__/Products.graphql')

const CatalogContainer: React.FC<object> = () => {
  const history = useHistory()
  const parsed = queryString.parse(history.location.search)
  const [selectedTags, setSelectedTags] = useState(
    (parsed.categories && parsed.categories.split(',')) ?? []
  )

  const [searchQuery, setSelectedSearchQuery] = useState(parsed.search)

  const { data, loading, fetchMore } = useQuery<products>(PRODUCTS, {
    variables: {
      nameLike: searchQuery,
      tagsSlugs: selectedTags,
      first: 10
    },
    skip: selectedTags.length === 0 && searchQuery === undefined
  })

  useEffect(() => {
    return history.listen((location) => {
      const parsed = queryString.parse(location.search)
      const newSearchParams =
        (parsed.categories && parsed.categories.split(',')) ?? []
      setSelectedTags(newSearchParams)
      setSelectedSearchQuery(parsed.search)
    })
  }, [history])

  const loadMore = () => {
    fetchMore({
      // note this is a different query than the one used in the
      // Query component
      query: PRODUCTS,
      variables: {
        after: data?.products.pageInfo.endCursor,
        nameLike: selectedTags?.length > 0 ? undefined : searchQuery,
        tagsSlugs: selectedTags,
        first: 10
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousEntry = previousResult.products
        const newProducts = fetchMoreResult?.products

        return {
          products: {
            pageInfo: newProducts?.pageInfo ?? previousEntry.pageInfo,
            edges: [
              ...(previousEntry.edges ?? []),
              ...(newProducts?.edges ?? [])
            ],
            nodes: [
              ...(previousEntry.nodes ?? []),
              ...(newProducts?.nodes ?? [])
            ],
            __typename: previousEntry.__typename
          },
          __typename: previousEntry.__typename
        }
      }
    })
  }

  return (
    <CatalogScreen
      loading={loading}
      loadMore={loadMore}
      pageInfo={data?.products.pageInfo}
      products={data?.products.nodes ?? []}
      selectedTags={selectedTags}
      searchQuery={searchQuery}
      clearSearch={() => {
        setSelectedSearchQuery(null)
        history.push(`/browse?categories=${selectedTags.join(',')}`)
      }}
      toggleTag={(categoryClicked: string) => {
        if (selectedTags.includes(categoryClicked)) {
          //Removing old tags
          const newTags = [
            ...selectedTags.filter((category) => category !== categoryClicked)
          ]
          if (searchQuery) {
            history.push(
              `/browse?search=${searchQuery}&categories=${newTags.join(',')}`
            )
          } else {
            history.push(`/browse?categories=${newTags.join(',')}`)
          }
        } else {
          //Pushing new tags
          const newTags = [...selectedTags, categoryClicked]
          if (searchQuery) {
            history.push(
              `/browse?search=${searchQuery}&categories=${newTags.join(',')}`
            )
          } else {
            history.push(`/browse?categories=${newTags.join(',')}`)
          }
        }
      }}
      setSelectedTags={setSelectedTags}
    />
  )
}

export default CatalogContainer
