import SignInContainer from 'modules/auth/SignIn.container'
import React from 'react'

interface Props {}

const SignInPage: React.FC<Props> = () => {
  return <SignInContainer />
}

export default SignInPage
