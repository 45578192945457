import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { FormikErrors } from 'formik'
import React from 'react'

import Footer from '../appbar/footer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50ch'
    },
    minHeight: 'calc(100vh - 175px)',
    textAlign: 'center'
  },
  subtitle: {
    marginTop: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2),
    textTransform: 'capitalize',
    minWidth: 100,
    borderRadius: 25,
    fontSize: '1rem'
  },
  textField: {
    background: theme.palette.primary.main
  }
}))

export interface ResetPasswordFormValues {
  newPassword?: string
  confirmPassword?: string
}

interface Props {
  values: ResetPasswordFormValues
  onChange(ev: React.ChangeEvent<HTMLInputElement>): void
  isSending: boolean
  errors?: FormikErrors<ResetPasswordFormValues>
  status?: any
  setStatus?(status: any): void
}

const ResetPasswordScreen: React.FC<Props> = (props: Props) => {
  const { values, onChange, isSending, errors, status } = props
  const { newPassword, confirmPassword } = values
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        {status?.success && (
          <Alert severity="success">Password has been successfully reset</Alert>
        )}
        {status?.error && !status.success && (
          <Alert severity="error">{status.error}</Alert>
        )}
        <Typography color="primary" variant="h6">
          Please set a new password to continue.
        </Typography>
        <Typography
          color="primary"
          variant="subtitle1"
          className={classes.subtitle}
        >
          To access the Hermes Technology Marketplace, please set a password:
        </Typography>
        <TextField
          id="newPassword"
          inputProps={{
            className: classes.textField
          }}
          color="secondary"
          type="password"
          autoComplete="new-password"
          label="New Password"
          variant="filled"
          value={newPassword}
          onChange={onChange}
          error={Boolean(errors?.newPassword)}
          helperText={errors?.newPassword}
          disabled={isSending}
        />
        <TextField
          id="confirmPassword"
          inputProps={{
            className: classes.textField
          }}
          color="secondary"
          type="password"
          autoComplete="off"
          label="Confirm Password"
          variant="filled"
          value={confirmPassword}
          onChange={onChange}
          error={Boolean(errors?.confirmPassword)}
          helperText={errors?.confirmPassword}
          disabled={isSending}
        />
        <Button
          type="submit"
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={isSending}
        >
          <Box fontWeight="fontWeightBold">Set Password</Box>
        </Button>
      </div>
      <Footer />
    </>
  )
}

export default ResetPasswordScreen
