import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import Footer from '../appbar/footer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    },
    padding: theme.spacing(2),
    minHeight: 'calc(100vh - 175px)',
    textAlign: 'center'
  },
  subtitle: {
    marginTop: theme.spacing(2)
  },
  button: {
    right: theme.spacing(0),
    textTransform: 'capitalize',
    minWidth: 100,
    borderRadius: 25,
    margin: theme.spacing(1),
    fontSize: '1rem'
  }
}))

interface Props {
  onClick(): void
}

const SignUpSuccessScreen: React.FC<Props> = (props: Props) => {
  const { onClick } = props
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        <Typography color="primary" variant="h4">
          Your request has been submitted.
        </Typography>
        <Typography
          color="primary"
          variant="subtitle1"
          className={classes.subtitle}
        >
          We will review your request within 72 business hours. Please check
          your inbox for an email notification.
        </Typography>
        <Button
          onClick={onClick}
          className={classes.button}
          variant="contained"
          color="primary"
          autoFocus
        >
          <Box fontWeight="fontWeightBold">Return to Home</Box>
        </Button>
      </div>
      <Footer />
    </>
  )
}

export default SignUpSuccessScreen
