import ResetPasswordContainer from 'modules/auth/ResetPassword.container'
import SendForgotPasswordContainer from 'modules/auth/SendForgotPassword.container'
import React from 'react'
import { useHistory } from 'react-router-dom'

const queryString = require('query-string')

interface Props {}

const ResetPasswordPage: React.FC<Props> = () => {
  const history = useHistory()
  const parsed = queryString.parse(history.location.search)
  const pinNumber = parsed.pinNumber || parsed.token
  return !pinNumber ? (
    <SendForgotPasswordContainer />
  ) : (
    <ResetPasswordContainer pinNumber={parsed.pinNumber || parsed.token} />
  )
}

export default ResetPasswordPage
