import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { FormikErrors } from 'formik'
import React from 'react'

import Footer from '../appbar/footer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2)
    },
    minHeight: 'calc(100vh - 175px)',
    textAlign: 'center'
  },
  subtitle: {
    marginTop: theme.spacing(2)
  },
  grid: {
    marginTop: theme.spacing(2),
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  mainForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    marginTop: theme.spacing(2),
    textTransform: 'capitalize',
    minWidth: 100,
    borderRadius: 25,
    fontSize: '1rem'
  },
  textField: {
    background: theme.palette.primary.main
  }
}))

export interface SetFirstProfileFormValues {
  firstName?: string
  lastName?: string
}

interface Props {
  values: SetFirstProfileFormValues
  onChange(ev: React.ChangeEvent<HTMLInputElement>): void
  setFieldValue(field: string, value: any): void
  isSaving: boolean
  errors?: FormikErrors<SetFirstProfileFormValues>
  error?: string
  status?: any
  setStatus?(status: any): void
  loading: boolean
}

const SetFirstProfileScreen: React.FC<Props> = (props: Props) => {
  const { values, onChange, isSaving, errors, error, status, loading } = props
  const { firstName, lastName } = values
  const classes = useStyles()

  return (
    <>
      <div className={classes.root}>
        {(error || (status?.error && !status.success)) && (
          <Alert severity="error">{error ?? status.error}</Alert>
        )}
        {!loading && (
          <>
            <Typography color="primary" variant="h6">
              Please confirm your first name and last name to continue.
            </Typography>
            <Typography
              color="primary"
              variant="subtitle1"
              className={classes.subtitle}
            >
              Please note that this operation cannot be undone:
            </Typography>
            <Grid className={classes.grid} container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.mainForm}>
                  <TextField
                    fullWidth
                    id="firstName"
                    inputProps={{
                      className: classes.textField
                    }}
                    color="secondary"
                    label="First Name"
                    variant="filled"
                    autoComplete="given-name"
                    value={firstName}
                    onChange={onChange}
                    error={Boolean(errors?.firstName)}
                    helperText={errors?.firstName}
                    disabled={isSaving}
                  />
                  <TextField
                    fullWidth
                    id="lastName"
                    inputProps={{
                      className: classes.textField
                    }}
                    color="secondary"
                    label="Last Name"
                    variant="filled"
                    autoComplete="family-name"
                    value={lastName}
                    onChange={onChange}
                    error={Boolean(errors?.lastName)}
                    helperText={errors?.lastName}
                    disabled={isSaving}
                  />

                  <Button
                    type="submit"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    disabled={isSaving}
                  >
                    <Box fontWeight="fontWeightBold">Save changes</Box>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </div>
      <Footer />
    </>
  )
}

export default SetFirstProfileScreen
