import red from '@material-ui/core/colors/red'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

// A custom theme for this app
let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#39B27B'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#0D242D'
    }
  },
  overrides: {
    MuiAvatar: {
      img: {
        // handle correctly non-square images
        objectFit: 'contain',
        height: '100%',
        backgroundColor: '#FFFFFF'
      }
    }
  }
})

theme = responsiveFontSizes(theme)

export default theme
