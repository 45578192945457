import { Button, Grid } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'

import {
  CategoryWheel1,
  CategoryWheel2,
  CategoryWheel3
} from '../../components'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center'
    },
    button: {
      width: 318,
      height: 48,
      right: theme.spacing(0),
      textTransform: 'capitalize',
      borderRadius: 25,
      fontSize: '24px',
      fontWeight: 'normal',
      fontFamily: 'Roboto',
      letterSpacing: '0.15px',
      backgroundColor: '#39B27B',
      '&:hover': {
        backgroundColor: '#39B27B'
      },
      marginTop: '88px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '32px'
      }
    },
    subCategoryPaper: {
      borderRadius: 20,
      borderColor: '#000'
    },
    h1: {
      color: 'white',
      textAlign: 'center',
      fontWeight: 'normal',
      fontSize: '48px',
      lineHeight: '56px',
      fontFamily: 'Raleway'
    },
    h3: {
      color: 'white',
      textAlign: 'center',
      fontWeight: 'normal',
      fontSize: '24px',
      lineHeight: '28px',
      fontFamily: 'Roboto'
    },
    h4: {
      color: 'white',
      textAlign: 'center',
      fontFamily: 'Raleway',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0.15px'
    },
    wheelContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  })
)

interface Props {
  selectedSubCategories: string[]
  onCategoryClick: (category: string) => void
  onSearch: () => void
}

const SearchScreen: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const { selectedSubCategories, onCategoryClick, onSearch } = props

  return (
    <div
      style={{ marginTop: 48, width: '100%', marginLeft: 70, marginRight: 70 }}
    >
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12} md={3}>
          <h1 className={classes.h1}>What are you looking for?</h1>
          <h3 className={classes.h3}>Choose all categories that apply.</h3>
        </Grid>

        <Grid container xs={12} md={9} spacing={1}>
          <Grid item xs={12} md={4} className={classes.wheelContainer}>
            <h4 className={classes.h4}>Intelligence Disciplines</h4>
            <CategoryWheel1
              selectedSubCategories={selectedSubCategories}
              onCategoryClick={onCategoryClick}
            />
          </Grid>

          <Grid item xs={12} md={4} className={classes.wheelContainer}>
            <h4 className={classes.h4}>F3EAD Cycle</h4>
            <CategoryWheel2
              selectedSubCategories={selectedSubCategories}
              onCategoryClick={onCategoryClick}
            />
          </Grid>

          <Grid item xs={12} md={4} className={classes.wheelContainer}>
            <h4 className={classes.h4}>Intelligence Cycle</h4>
            <CategoryWheel3
              selectedSubCategories={selectedSubCategories}
              onCategoryClick={(c) => onCategoryClick(c)}
            />
          </Grid>

          <Grid container justify="center">
            <Button onClick={onSearch} className={classes.button}>
              SEARCH
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default SearchScreen
