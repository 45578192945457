import { useMutation } from '@apollo/react-hooks'
import LinearProgress from '@material-ui/core/LinearProgress'
import {
  signIn,
  signInVariables,
  signIn_signInClassic_result,
  signIn_signInClassic_result_ValidationProblem
} from '__generated__/signIn'
import { validatePassword } from 'auth/services/UserInputValidation'
import { useFormik, FormikErrors } from 'formik'
import { loader } from 'graphql.macro'
import useLoggedInCallback from 'hooks/LoggedInHook'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useHistory } from 'react-router-dom'
import GoogleUtil from 'service/GoogleUtil'
import { getErrorMessage } from 'utils'
import * as Yup from 'yup'

import GA from '../../service/GoogleAnalytics'
import SignInScreen, { SignInFormValues } from './SignIn.screen'
const SIGNIN = loader('./__graphql__/SignIn.graphql')

interface Props {}

const SignInContainer: React.FC<Props> = () => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const loggedInCallback = useLoggedInCallback()
  const [signInClassic] = useMutation<signIn, signInVariables>(SIGNIN)

  const retrieveCredentials = async () => {
    if (window.PasswordCredential || window.FederatedCredential) {
      await GoogleUtil.init()
      try {
        const c = await navigator.credentials.get({
          // @ts-ignore
          password: true,
          federated: {
            providers: ['https://accounts.google.com']
          },
          mediation: 'silent'
        })
        if (c) {
          switch (c.type) {
            case 'password':
              try {
                const signInRes = await signInClassic({
                  variables: {
                    input: {
                      email: c.id,
                      password: (c as any).password
                    }
                  }
                })
                const result = signInRes?.data?.signInClassic?.result
                if (result?.__typename === 'SignInOk') {
                  const user = result.viewer.currentUser
                  if (user && loggedInCallback) {
                    switch (user.__typename) {
                      case 'RegularUser':
                        break
                      case 'Buyer':
                        loggedInCallback(
                          {
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            avatarUrl: user.avatarUrl ?? undefined
                          },
                          result.jwt,
                          result.jwtExpiry
                        )
                    }
                  }
                  window.google?.accounts.id.cancel()
                  history.replace('/')
                  localStorage.setItem('refreshToken', result.refreshToken)
                } else {
                  enqueueSnackbar(
                    (result as signIn_signInClassic_result_ValidationProblem)
                      ?.message,
                    { variant: 'error' }
                  )
                }
              } catch (err) {
                console.error(err)
                const errorMsg = getErrorMessage(
                  err,
                  err.toString() || 'Failed to sign in'
                )
                enqueueSnackbar(errorMsg, { variant: 'error' })
              }
              break
          }
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  const initGoogleAndGetCred = async () => {
    try {
      await GoogleUtil.init()
      // window.google?.accounts.id.cancel()
      setTimeout(() => {
        retrieveCredentials()
      }, 300)
    } catch (err) {
      console.error('Error initializing Google', err)
    }
  }

  React.useEffect(() => {
    initGoogleAndGetCred()
  }, [])

  const callSigninClassic = async (
    email: string,
    password: string
  ): Promise<signIn_signInClassic_result | undefined> => {
    const signInRes = await signInClassic({
      variables: {
        input: { email, password }
      }
    })
    return signInRes?.data?.signInClassic?.result
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: async (values, actions) => {
      try {
        const result = await callSigninClassic(values.email, values.password)
        if (result?.__typename === 'SignInOk') {
          GA.sendEvent({
            category: 'SignInOk',
            action: 'User is logged in'
          })
          const user = result.viewer.currentUser
          if (user && loggedInCallback) {
            switch (user.__typename) {
              case 'RegularUser':
                break
              case 'Buyer':
                loggedInCallback(
                  {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    avatarUrl: user.avatarUrl ?? undefined
                  },
                  result.jwt,
                  result.jwtExpiry
                )
                if (window.PasswordCredential) {
                  const c = {
                    id: values.email,
                    password: values.password,
                    name: `${user.firstName} ${user.lastName}`
                  }
                  const cred = await (navigator.credentials as any).create({
                    password: c
                  })
                  if (cred) {
                    await navigator.credentials.store(cred)
                  }
                }
                actions.setStatus({ success: true })
                window.google?.accounts.id.cancel()
                history.replace('/')
                localStorage.setItem('refreshToken', result.refreshToken)
            }
          } else {
            actions.setStatus({ success: false, error: 'Error' })
          }
        } else if (result?.__typename === 'ValidationProblem') {
          actions.setStatus({ success: false, error: result.message })
          if (result.errors && result.errors.length > 0) {
            const formikErrors: FormikErrors<SignInFormValues> = {}
            result.errors.forEach(
              (err) =>
                (formikErrors[
                  err.field as 'email' | 'password'
                ] = err.messages.join(', '))
            )
            actions.setErrors(formikErrors)
          }
        }
      } catch (err) {
        console.log(err)
        const errorMsg = getErrorMessage(err, 'Failed to save')
        actions.setStatus({ success: false, error: errorMsg })
      } finally {
        actions.setSubmitting(false)
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required('Email is a required field'),
      password: Yup.string()
        .required('Password is a required field')
        .test(
          'valid-password',
          'Password needs to be at least 8 characters long and contain one or more symbols',
          (value) => validatePassword(value)
        )
    })
  })
  return (
    <>
      {formik.isSubmitting && <LinearProgress />}
      <form onSubmit={formik.handleSubmit}>
        <SignInScreen
          values={formik.values}
          onChange={formik.handleChange}
          isSigningIn={formik.isSubmitting}
          errors={formik.errors}
          status={formik.status}
          setStatus={formik.setStatus}
        />
      </form>
    </>
  )
}

export default SignInContainer
