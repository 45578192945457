import { useQuery } from '@apollo/react-hooks'
import { product } from '__generated__/product'
import { loader } from 'graphql.macro'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getErrorMessage } from 'utils'

import ProductScreen from './Product.screen'

const PRODUCT = loader('./__graphql__/Product.graphql')

interface Props {
  productId: string
}

const ProductContainer: React.FC<Props> = (props: Props) => {
  const { productId } = props
  const { error, data, loading } = useQuery<product>(PRODUCT, {
    variables: { id: productId }
  })
  const history = useHistory()
  const productData = data?.product

  if (error) {
    history.replace('/')
  }

  const values = {
    name: productData?.name ?? '',
    description: productData?.description ?? '',
    features: productData?.features ?? '',
    useCases: productData?.useCases ?? '',
    vettingLevel: productData?.company.vettingLevel ?? '',
    scrm: productData?.scrm ?? '',
    images: productData?.imagesUrls ?? [],
    pdfUrl: productData?.pdfUrl ?? '',
    demoUrl: productData?.demoLink ?? '',
    location: productData?.company.location ?? '',
    companyName: productData?.company.name ?? '',
    website: productData?.company.website ?? '',
    companyLogoUrl: productData?.company.logoUrl ?? '',
    companyId: productData?.company.id ?? ''
  }

  return (
    <ProductScreen
      values={values}
      loading={loading}
      error={
        error ? getErrorMessage(error, 'Failed to get product info') : undefined
      }
    />
  )
}

export default ProductContainer
