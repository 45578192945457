import InviteExpiredContainer from 'modules/auth/InviteExpired.container'
import SetFirstPasswordContainer from 'modules/auth/SetFirstPassword.container'
import SetFirstProfileContainer from 'modules/auth/SetFirstProfile.container'
import React from 'react'
import { useHistory } from 'react-router-dom'

const queryString = require('query-string')

interface Props {}

const SetFirstPasswordPage: React.FC<Props> = (props) => {
  const [isValidPinNumber, setValidPinNumber] = React.useState(true)
  const [isFirstPasswordChange, setFirstPasswordChange] = React.useState(false)
  const history = useHistory()
  const parsed = queryString.parse(history.location.search)
  return isValidPinNumber ? (
    !isFirstPasswordChange ? (
      <SetFirstPasswordContainer
        pinNumber={parsed.pinNumber || parsed.resetToken}
        onCheckValid={setValidPinNumber}
        onFirstPasswordChange={setFirstPasswordChange}
      />
    ) : (
      <SetFirstProfileContainer />
    )
  ) : (
    <InviteExpiredContainer />
  )
}

export default SetFirstPasswordPage
