import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
  Drawer,
  useMediaQuery
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { products_products_nodes } from '__generated__/products'
import { CategoryWheel1, CategoryWheel2, CategoryWheel3 } from 'components'
import ProductCard from 'modules/product/ProductCard'
import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoryContainer: {
      borderRadius: 10,
      marginRight: 17,
      marginBottom: 20,
      width: 75,
      height: 75,
      '&:hover': {
        cursor: 'pointer'
      }
    },
    categoryImage: {
      width: 75,
      height: 75
    },
    categories: {
      backgroundColor: '#35474E',
      borderWidth: 0,
      [theme.breakpoints.down('sm')]: {
        width: '265px',
        borderRadius: 0
      }
    },
    refine: {
      color: 'white',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 'bold',
      textTransform: 'none'
    }
  })
)

interface Props {
  products: (products_products_nodes | null)[]
  selectedTags: string[]
  searchQuery: string
  loading: boolean
  loadMore: () => void
  // tags: allTags_allTags[]
  toggleTag: (tagId: string) => void
  setSelectedTags: (selectedTags: string[]) => void
  clearSearch: () => void
  pageInfo: any
}

interface TagProps {
  selectedTags: string[]
  toggleTag: (tag: string) => void
}

const TagCategoryAccordian = (props: TagProps) => {
  const { toggleTag, selectedTags } = props
  const classes = useStyles()

  const isSpaceOn = selectedTags.includes('space')
  const isCyberOn = selectedTags.includes('cyber')
  const isAutonomyOn = selectedTags.includes('autonomy')
  const isEtceteraOn = selectedTags.includes('etcetera')
  const isHumanSystemsOn = selectedTags.includes('human-systems')
  const isAiOn = selectedTags.includes('artificial-intelligence')

  const isAiMlOn = selectedTags.includes('ai-and-ml')
  const isIntelligenceCollectionOn = selectedTags.includes(
    'intelligence-collection'
  )
  const isEmergingTechnologiesOn = selectedTags.includes(
    'emerging-technologies'
  )
  const isNewAnalysisOn = selectedTags.includes(
    'new-analysis-technologies-and-methods'
  )
  const isMissionOn = selectedTags.includes(
    'mission-enhancing-science-and-technology'
  )
  const isIncreaseOn = selectedTags.includes(
    'increase-organizational-effectiveness'
  )
  const isEnhanceOn = selectedTags.includes(
    'enhance-counterintelligence-and-security'
  )
  const isImproveOn = selectedTags.includes(
    'improve-mission-support-and-capabilities'
  )
  const isEmpowerOn = selectedTags.includes('empower-partnerships')

  return (
    <div>
      <Accordion style={{ backgroundColor: '#35474E' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{ color: 'white' }}>
            Intelligence Disciplines
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CategoryWheel1
            selectedSubCategories={selectedTags}
            onCategoryClick={toggleTag}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ backgroundColor: '#35474E' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{ color: 'white' }}>F3EAD Cycle</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CategoryWheel2
            selectedSubCategories={selectedTags}
            onCategoryClick={toggleTag}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ backgroundColor: '#35474E' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{ color: 'white' }}>Intelligence Cycle</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CategoryWheel3
            selectedSubCategories={selectedTags}
            onCategoryClick={toggleTag}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ backgroundColor: '#35474E' }} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{ color: 'white' }}>Focus Areas</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={3} justify="center">
            <Paper
              onClick={() => toggleTag('space')}
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isSpaceOn ? (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/space-on.png'}
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/space.png'}
                  />
                )}
              </Grid>
            </Paper>
            <Paper
              onClick={() => toggleTag('cyber')}
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isCyberOn ? (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/cyber-on.png'}
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/cyber.png'}
                  />
                )}
              </Grid>
            </Paper>
            <Paper
              onClick={() => toggleTag('autonomy')}
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isAutonomyOn ? (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/autonomy-on.png'}
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/autonomy.png'}
                  />
                )}
              </Grid>
            </Paper>

            <Paper
              onClick={() => toggleTag('human-systems')}
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isHumanSystemsOn ? (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/human-systems-on.png'}
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/human-systems.png'}
                  />
                )}
              </Grid>
            </Paper>
            <Paper
              onClick={() => toggleTag('artificial-intelligence')}
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isAiOn ? (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/ai-on.png'}
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/ai.png'}
                  />
                )}
              </Grid>
            </Paper>
            <Paper
              onClick={() => toggleTag('etcetera')}
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isEtceteraOn ? (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/etcetera-on.png'}
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/etcetera.png'}
                  />
                )}
              </Grid>
            </Paper>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion style={{ backgroundColor: '#35474E' }} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{ color: 'white' }}>Current Needs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3} justify="center">
            <Paper
              onClick={() => toggleTag('ai-and-ml')}
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isAiMlOn ? (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/ai-ml-on.png'}
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/ai-ml.png'}
                  />
                )}
              </Grid>
            </Paper>
            <Paper
              onClick={() => toggleTag('intelligence-collection')}
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isIntelligenceCollectionOn ? (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin + '/intelligence-collection-on.png'
                    }
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin + '/intelligence-collection.png'
                    }
                  />
                )}
              </Grid>
            </Paper>
            <Paper
              onClick={() => toggleTag('emerging-technologies')}
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isEmergingTechnologiesOn ? (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin + '/emerging-technologies-on.png'
                    }
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/emerging-technologies.png'}
                  />
                )}
              </Grid>
            </Paper>
            <Paper
              onClick={() => toggleTag('new-analysis-technologies-and-methods')}
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isNewAnalysisOn ? (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin +
                      '/new-analysis-technologies-methods-on.png'
                    }
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin +
                      '/new-analysis-technologies-methods.png'
                    }
                  />
                )}
              </Grid>
            </Paper>
            <Paper
              onClick={() =>
                toggleTag('mission-enhancing-science-and-technology')
              }
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isMissionOn ? (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin +
                      '/mission-enhancing-science-technology-on.png'
                    }
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin +
                      '/mission-enhancing-science-technology.png'
                    }
                  />
                )}
              </Grid>
            </Paper>
            <Paper
              onClick={() => toggleTag('increase-organizational-effectiveness')}
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isIncreaseOn ? (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin +
                      '/increase-organizational-effectiveness-on.png'
                    }
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin +
                      '/increase-organizational-effectiveness.png'
                    }
                  />
                )}
              </Grid>
            </Paper>
            <Paper
              onClick={() =>
                toggleTag('enhance-counterintelligence-and-security')
              }
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isEnhanceOn ? (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin +
                      '/enhance-counterintelligence-and-security-on.png'
                    }
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin +
                      '/enhance-counterintelligence-and-security.png'
                    }
                  />
                )}
              </Grid>
            </Paper>
            <Paper
              onClick={() =>
                toggleTag('improve-mission-support-and-capabilities')
              }
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isImproveOn ? (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin +
                      '/improve-mission-support-and-capabilities-on.png'
                    }
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin +
                      '/improve-mission-support-and-capabilities.png'
                    }
                  />
                )}
              </Grid>
            </Paper>
            <Paper
              onClick={() => toggleTag('empower-partnerships')}
              className={classes.categoryContainer}
            >
              <Grid item xs={6} md={4}>
                {isEmpowerOn ? (
                  <img
                    className={classes.categoryImage}
                    src={
                      window.location.origin + '/empower-partnerships-on.png'
                    }
                  />
                ) : (
                  <img
                    className={classes.categoryImage}
                    src={window.location.origin + '/empower-partnerships.png'}
                  />
                )}
              </Grid>
            </Paper>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const CatalogScreen: React.FC<Props> = (props) => {
  const {
    selectedTags,
    products,
    searchQuery,
    clearSearch,
    setSelectedTags,
    toggleTag,
    loading,
    loadMore,
    pageInfo
  } = props
  const history = useHistory()
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const empty = (
    <Grid container xs={isMobile ? 12 : 9}>
      <Grid item xs={9} container justify="center">
        <h3 style={{ color: 'white' }}>
          Currently no products match these search results. Please try another
          search.
        </h3>
      </Grid>

      {isMobile ? (
        <Grid item xs={3} container justify="flex-end">
          <Button
            className={classes.refine}
            onClick={() => setDrawerOpen(!isDrawerOpen)}
          >
            + Refine
          </Button>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  )

  const loader = (
    <Grid item xs={12}>
      <div style={{ textAlign: 'center', margin: 16 }}>
        <CircularProgress />
      </div>
    </Grid>
  )

  const rightSide = (
    <Grid item xs={isMobile ? 12 : 9}>
      <Grid
        container
        xs={12}
        alignItems="flex-start"
        style={{ marginBottom: 18 }}
      >
        <Grid item xs={isMobile ? 9 : 12}>
          {selectedTags || searchQuery ? (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                marginBottom: '8px'
              }}
            >
              <Typography color="primary" style={{ marginRight: '4px' }}>
                {products.length} search results for
              </Typography>
              {selectedTags && selectedTags.length > 0
                ? selectedTags.map((tag) => {
                    return (
                      <Chip
                        color="primary"
                        label={tag}
                        onDelete={() => {
                          toggleTag(tag)
                        }}
                        style={{ margin: '4px' }}
                      />
                    )
                  })
                : null}
              {searchQuery && searchQuery.length > 0 ? (
                <Chip
                  color="primary"
                  label={searchQuery}
                  onDelete={clearSearch}
                  style={{ margin: '4px' }}
                />
              ) : null}
            </div>
          ) : null}
        </Grid>

        {isMobile ? (
          <Grid item xs={3} container justify="flex-end">
            <Button
              className={classes.refine}
              onClick={() => setDrawerOpen(!isDrawerOpen)}
            >
              + Refine
            </Button>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>

      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={pageInfo?.hasNextPage}
        loader={loader}
      >
        <Grid container item xs={12} spacing={4} alignItems="center">
          {products?.map((product) => {
            if (!product) {
              return
            }

            return (
              <Grid item xs={6} sm={4} key={product.id}>
                <ProductCard
                  values={{
                    imagesUrls: product.imagesUrls,
                    id: product.id,
                    name: product.name,
                    companyName: product.company.name,
                    vettingLevel: product.company.vettingLevel,
                    location: product.company.location
                  }}
                  onClick={() => {
                    history.push(`/product/${product.id}`)
                  }}
                />
              </Grid>
            )
          })}
        </Grid>
      </InfiniteScroll>
    </Grid>
  )

  const categories = (
    <Paper className={classes.categories}>
      <div
        style={{
          padding: 12,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div>
          {isMobile ? (
            <Button
              className={classes.refine}
              onClick={() => setDrawerOpen(false)}
            >
              - Refine
            </Button>
          ) : (
            <h2 style={{ color: 'white' }}>Categories</h2>
          )}
        </div>
        {selectedTags.length > 0 && (
          <div>
            <Button
              style={{ color: 'white' }}
              onClick={() => {
                history.push('/browse')
                setSelectedTags([])
              }}
            >
              Clear Search
            </Button>
          </div>
        )}
      </div>
      <Divider />
      <TagCategoryAccordian toggleTag={toggleTag} selectedTags={selectedTags} />
    </Paper>
  )

  const leftSide = (
    <Grid item xs={3}>
      {categories}
    </Grid>
  )

  const [isDrawerOpen, setDrawerOpen] = useState(false)

  return (
    <>
      {isMobile && (
        <Drawer
          transitionDuration={0}
          open={isDrawerOpen}
          anchor="right"
          onClose={() => setDrawerOpen(false)}
        >
          <div style={{ backgroundColor: '#35474E', height: '100%' }}>
            {categories}
          </div>
        </Drawer>
      )}
      <div style={{ margin: 16 }}>
        <Grid container spacing={2}>
          {!isMobile && leftSide}
          {loading ? loader : products.length == 0 ? empty : rightSide}
        </Grid>
      </div>
    </>
  )
}

export default CatalogScreen
