import { useQuery } from '@apollo/react-hooks'
import { useMediaQuery } from '@material-ui/core'
import AppBarMUI from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import { currentUser } from '__generated__/currentUser'
import { loader } from 'graphql.macro'
import useLogout from 'hooks/LogoutHook'
import useCurrentUser from 'hooks/UserHook'
import React from 'react'
import { useHistory, Link as RouterLink } from 'react-router-dom'

import SearchBar from './searchbar'

const CURRENT_USER = loader('../profile/__graphql__/CurrentUser.graphql')

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  appbar: {
    background: '#0D242D',
    boxShadow: 'none',
    marginTop: 17
  },
  title: {
    marginRight: theme.spacing(2),
    textDecoration: 'none'
  },
  button: {
    right: theme.spacing(0),
    textTransform: 'capitalize',
    minWidth: 100,
    borderRadius: 4,
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: 48,
      minHeight: 48,
      borderRadius: 30,
      '& .MuiButton-startIcon': {
        margin: 0
      }
    }
  },
  buttonText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  profileIcon: {
    width: 32,
    height: 32
  },
  login: {
    right: theme.spacing(0),
    textTransform: 'capitalize',
    minWidth: 100,
    borderRadius: 4,
    fontSize: '1rem'
  },
  placeholder: {
    flexGrow: 1
  },
  profile: {
    textDecoration: 'none',
    minWidth: '5%',
    textTransform: 'none'
  },
  menu: {
    '& .MuiMenu-paper': {
      marginLeft: theme.spacing(-0.65)
    }
  },
  logo: {
    '&:hover': {
      cursor: 'pointer'
    },
    width: '90%',
    maxHeight: 30
  }
}))

interface Props {}

const AppBar: React.FC<Props> = () => {
  const user = useCurrentUser()
  const logout = useLogout()
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { data, loading } = useQuery<currentUser>(CURRENT_USER)
  const open = Boolean(anchorEl)
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleProfile = () => {
    setAnchorEl(null)
    history.push('/profile')
  }

  const handleLogoClick = () => {
    history.push('/')
  }

  if (!loading) {
    const currentUser = data?.viewer?.currentUser
    if (currentUser && user) {
      // make sure to display updated user profile
      user.firstName = currentUser.firstName
      user.lastName = currentUser.lastName
    }
  }

  return (
    <div className={classes.root}>
      <AppBarMUI position="static" className={classes.appbar}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item md={3} sm={4} xs={3}>
              <img
                src="/logo.svg"
                onClick={handleLogoClick}
                className={classes.logo}
              />
            </Grid>

            <Grid item md={6} sm={6} xs={7}>
              {user ? <SearchBar /> : <></>}
            </Grid>

            <Grid container item md={3} sm={2} xs={2} justify="flex-end">
              {user ? (
                <>
                  <Button
                    onClick={handleMenu}
                    variant={isSm ? undefined : 'contained'}
                    color="primary"
                    className={classes.button}
                  >
                    {isSm ? (
                      <img src="/profile.png" className={classes.profileIcon} />
                    ) : (
                      <Box
                        className={classes.buttonText}
                        fontWeight="fontWeightBold"
                      >
                        {user.firstName || 'User'}
                      </Box>
                    )}
                  </Button>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={open}
                    onClose={handleClose}
                    className={classes.menu}
                  >
                    <MenuItem onClick={handleProfile}>
                      <Box fontWeight="fontWeightBold">Profile</Box>
                    </MenuItem>
                    <MenuItem onClick={logout}>
                      <Box fontWeight="fontWeightBold">Logout</Box>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Button
                  component={RouterLink}
                  variant="contained"
                  color="primary"
                  to="/login"
                  className={classes.login}
                >
                  <Box fontWeight="fontWeightBold">Login</Box>
                </Button>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBarMUI>
    </div>
  )
}

export default AppBar
