const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

const loadGoogleAPI = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById('googleApi')
    if (!existingScript) {
      const script = document.createElement('script')
      script.src = 'https://apis.google.com/js/client:platform.js?onload=start'
      script.id = 'googleApi'
      script.async = true
      script.defer = true
      document.body.appendChild(script)
      script.onload = () => {
        console.log('google api loaded')
        resolve()
      }
    } else {
      resolve()
    }
    // reject('Failed to load google API')
  })
}

const init = async (): Promise<void> => {
  if (!GOOGLE_CLIENT_ID) {
    throw new Error('Google Client ID is not set')
  }
  await loadGoogleAPI()

  return new Promise((resolve, reject) => {
    window.gapi?.load('auth2', () => {
      window.auth2 = window.gapi?.auth2.init({
        client_id: GOOGLE_CLIENT_ID
      })
      window.auth2
        ?.then(() => {
          resolve()
        })
        .catch((err: any) => {
          reject(err)
          console.log('Error initializing Google API')
        })
    })
  })
}

const login = async (): Promise<any> => {
  if (!window.auth2) {
    throw Error('Google API is not initialized')
  }
  return window.auth2.signIn()
}

const initOneTapPopup = (
  handleCredentialResponse: (res: any) => void,
  handleNativeCredentialResponse: (res: any) => void,
  notificationCallback: (notification: any) => void
) => {
  window.google?.accounts?.id.initialize({
    client_id: GOOGLE_CLIENT_ID,
    cancel_on_tap_outside: false,
    // native_callback: handleNativeCredentialResponse,
    callback: handleCredentialResponse
  })
  window.google?.accounts.id?.prompt(notificationCallback)
}

export default {
  init,
  login,
  initOneTapPopup
}
