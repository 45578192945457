import useCurrentUser from 'hooks/UserHook'
import CompanyContainer from 'modules/product/Company.container'
import React from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

interface Props {}

const CompanyPage: React.FC<Props> = () => {
  const { companyId } = useParams<{ companyId: string }>()
  const user = useCurrentUser()
  const history = useHistory()
  React.useEffect(() => {
    if (!user) {
      history.replace('/login')
    }
  }, [])

  return user ? <CompanyContainer companyId={companyId} /> : null
}

export default CompanyPage
