import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import SearchScreen from './Search.screen'

const SearchContainer: React.FC<object> = () => {
  const history = useHistory()
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])

  return (
    <SearchScreen
      onSearch={() =>
        history.push(`/browse?categories=${selectedCategories.join(',')}`)
      }
      selectedSubCategories={selectedCategories}
      onCategoryClick={(categoryClicked) => {
        if (selectedCategories.includes(categoryClicked)) {
          setSelectedCategories([
            ...selectedCategories.filter(
              (category) => category !== categoryClicked
            )
          ])
        } else {
          setSelectedCategories([...selectedCategories, categoryClicked])
        }
      }}
    />
  )
}

export default SearchContainer
