import { ListItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import Footer from '../appbar/footer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    },
    minHeight: 'calc(100vh - 175px)',
    textAlign: 'center'
  },
  subtitle: {
    marginTop: theme.spacing(2)
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& a': {
      color: theme.palette.secondary.main
    }
  }
}))

interface Props {}

const InviteExpiredScreen: React.FC<Props> = () => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        <Typography color="primary" variant="h4">
          Your invitation has expired.
        </Typography>
        <Typography
          color="primary"
          variant="subtitle1"
          className={classes.subtitle}
        >
          To access the Hermes Technology Marketplace, please contact us to
          request a new invitation at:
        </Typography>
        <ListItem color="secondary" className={classes.link}>
          <a href="mailto:support@hermesmarket.io" target="_top">
            support@hermesmarket.io
          </a>
        </ListItem>
      </div>
      <Footer />
    </>
  )
}

export default InviteExpiredScreen
